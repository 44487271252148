<script>
import { Bar, mixins } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    Chart.plugins.unregister(ChartDataLabels);
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.chartData, this.options);
  },
};
</script>
