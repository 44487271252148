<template>
  <div id="view-dashboard" class="px-3 pb-3 pt-4 pt-md-5">
    <div class="px-3 pb-3">
      <p class="text-muted mb-0 font-weight-bold">
        <i class="fas fa-chart-pie mr-2"></i>
        DASHBOARD
      </p>
    </div>
    <div class="row mx-0">
      <div class="col-12 col-md-6 px-0">
        <div class="row mx-0 dashboard-cards">
          <div class="col-6">
            <div>
              <p class="card-title">Total Sites</p>
              <h3>{{ sites.length }}</h3>
            </div>
          </div>
          <div class="col-6">
            <div>
              <p class="card-title">Sharer Ratio</p>
              <h3>{{ Helper.getRatio(5, totalSharers) }}</h3>
            </div>
          </div>
        </div>
        <div class="row mx-0 dashboard-cards">
          <div class="col-12">
            <div>
              <p class="card-title">States Breakdown</p>
              <div class="w-100" style="height: 200px">
                <bar-chart
                  class="w-100 h-100"
                  v-if="statesBreakdown"
                  :chart-data="statesBreakdown"
                  :options="chartOptions.bar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 px-0">
        <div class="row mx-0 dashboard-cards">
          <div class="col-6" v-for="telco in telcoBreakdown" :key="telco._id">
            <div>
              <p
                class="card-title d-flex justify-content-center align-items-center"
                style="height: 40px"
              >
                <img
                  v-if="telco.logo"
                  :src="Helper.formatMediaUrl(telco.logo)"
                  class="w-auto h-100"
                />
                <span v-else>
                  {{ telco.name }}
                </span>
              </p>
              <h3>{{ telco.site }}</h3>
              <p class="card-title text-center">SITES</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/BarChart";

export default {
  name: "dashboard-view",
  components: {
    BarChart,
  },
  data() {
    return {
      sites: [],
      telcos: [],
      chartOptions: {
        bar: {
          plugins: {
            datalabels: {
              color: "#FFFFFF",
              font: {
                weight: "bold",
              },
              backgroundColor: "#365975",
              padding: {
                left: 10,
                right: 10,
              },
              borderRadius: 5,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  stepSize: 1,
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      },
    };
  },
  computed: {
    telcoBreakdown() {
      let result = this.telcos.map((telco) => {
        return {
          _id: telco._id,
          name: telco.name,
          logo: telco.logo,
          site: 0,
        };
      });

      this.sites.forEach((site) => {
        if (site.sharerinformations && site.sharerinformations.length > 0) {
          site.sharerinformations.forEach((sharer) => {
            let telco = result.find((r) => r._id == sharer.telco);

            if (telco) {
              telco.site += 1;
            }
          });
        }
      });

      return result.filter((r) => r.site > 0);
    },
    totalSharers() {
      let result = 0;

      this.sites.forEach((site) => {
        if (site.sharerinformations) {
          result += site.sharerinformations.length;
        }
      });

      return result;
    },
    statesBreakdown() {
      let result = [];

      this.sites.forEach((site) => {
        if (site.state) {
          let resultState = result.find(
            (r) => r.state.toLowerCase() == site.state.name.toLowerCase()
          );

          if (resultState) {
            resultState.count = (resultState.count || 1) + 1;
          } else {
            result.push({
              state: site.state.name.toUpperCase(),
              count: 1,
            });
          }
        }
      });

      let data = {
        labels: result.map((r) => r.state),
        datasets: [
          {
            label: "Site",
            barPercentage: 0.5,
            backgroundColor: "#A5CEE2",
            data: result.map((r) => r.count),
            datalabels: {
              align: "start",
              anchor: "end",
              display: function (context) {
                return context.dataset.data[context.dataIndex] > 0;
              },
            },
          },
        ],
      };

      return data;
    },
  },
  methods: {
    toggleSpinner(e) {
      this.$parent.toggleSpinner(e);
    },
    async getSites() {
      this.toggleSpinner(true);

      this.API.get("sites?_limit=-1")
        .then((retVal) => {
          this.sites = retVal.data;

          this.toggleSpinner(false);

          return;
        })
        .catch((err) => {
          this.toggleSpinner(false);

          return;
        });
    },
    async getTelcos() {
      this.toggleSpinner(true);

      this.API.get("telcos?_limit=-1")
        .then((retVal) => {
          this.telcos = retVal.data;

          this.toggleSpinner(false);

          return;
        })
        .catch((err) => {
          this.toggleSpinner(false);

          return;
        });
    },
  },
  async mounted() {
    await this.getSites();
    await this.getTelcos();
  },
};
</script>